.Nav {
  height: 4rem;
  text-align: center;
}

.slantbar {
  display:inline-block;
  position:relative;
  height:1.5rem;
  width:0.75rem;
  margin-left:0rem;
  margin-right:0.1em;
  background-color:rgba(0,100,255,1);
  transform:skewx(-7.5deg);
}

.lightblue {
  background-color:rgba(76,167,255,1);
}

img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.Nav-menuButton {
  color: rgba(255,255,255);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
